<template>
    <div>
    </div>
</template>

<script>
export default {
    name: "organPayCode",
    data() {
        return {
        }
    },

    computed:{
        user() {
            return this.$store.state.user;
        },
    },
    // 在 Vue 组件中使用 $watch 监听 Vuex 数据的变化
    watch: {
        user() {}
    },

    props: {
        sign: {
            type: String,
            default() {
                return {}
            }
        },
        type: {
            type: Number,
            default() {
                return {}
            }
        },
    },

    mounted() {
        this.checkPayParams();
    },

    methods: {
        //立即支付
        checkPayParams(){
            //支付
            if(this.type == 1){
                this.applyBankCardPay()
            }
            //实时转账
            if(this.type == 2){
                this.transferMoneyToPay()
            }
            //延时分账
            if(this.type == 3){
                this.delayedAccountToPay()
            }
        },
        //创建支付对象申请 返回订单
        applyBankCardPay() {
            var that = this;
            that.newApi.corpPaymentCreatePay({sign:that.sign}).then((res) => {
                if(res.isSuccess == 1){
                    const routeData = that.$router.resolve({path: '/toPay/' + res.data.order_no, query: {type: 1}});
                    //到newAdapay页面去跳转支付页面
                    that.$emit('change',{url:routeData.href,orderNo:res.data.orderNo});
                }
            })
        },

        // 创建实时转账对象申请 返回订单
        transferMoneyToPay() {
            var that = this;
            that.newApi.corpTransferMoneyDetermine({sign:that.sign}).then((res) => {
                if(res.isSuccess == 1){
                    const routeData = that.$router.resolve({path: '/toPay/' + res.data.order_no, query: {type: 2}});
                    //到newAdapay页面去跳转支付页面
                    that.$emit('change',{url:routeData.href,orderNo:res.data});
                }
            })
        },

        // 创建分账转账对象申请 返回订单
        delayedAccountToPay() {
            var that = this;
            that.newApi.corpPenny({sign:that.sign}).then((res) => {
                if(res.isSuccess == 1){
                    const routeData = that.$router.resolve({path: '/toPay/' + res.data});
                    //到newAdapay页面去跳转支付页面
                    that.$emit('change',{url:routeData.href});
                }
            })
        },
    }
}
</script>

<style scoped>

</style>